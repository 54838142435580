trix-editor {
  border: 1px solid #bbb;
  border-radius: 3px;
  margin: 0;
  padding: 0.4em 0.6em;
  min-height: 5em;
  outline: none; }

trix-toolbar * {
  box-sizing: border-box; }

trix-toolbar .trix-button-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow-x: auto; }

trix-toolbar .trix-button-group {
  display: flex;
  margin-bottom: 10px;
  border: 1px solid #bbb;
  border-top-color: #ccc;
  border-bottom-color: #888;
  border-radius: 3px; }
  trix-toolbar .trix-button-group:not(:first-child) {
    margin-left: 1.5vw; }
    @media (max-device-width: 768px) {
      trix-toolbar .trix-button-group:not(:first-child) {
        margin-left: 0; } }

trix-toolbar .trix-button-group-spacer {
  flex-grow: 1; }
  @media (max-device-width: 768px) {
    trix-toolbar .trix-button-group-spacer {
      display: none; } }

trix-toolbar .trix-button {
  position: relative;
  float: left;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  font-weight: 600;
  white-space: nowrap;
  padding: 0 0.5em;
  margin: 0;
  outline: none;
  border: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  background: transparent; }
  trix-toolbar .trix-button:not(:first-child) {
    border-left: 1px solid #ccc; }
  trix-toolbar .trix-button.trix-active {
    background: #cbeefa;
    color: black; }
  trix-toolbar .trix-button:not(:disabled) {
    cursor: pointer; }
  trix-toolbar .trix-button:disabled {
    color: rgba(0, 0, 0, 0.125); }
  @media (max-device-width: 768px) {
    trix-toolbar .trix-button {
      letter-spacing: -0.01em;
      padding: 0 0.3em; } }

trix-toolbar .trix-button--icon {
  font-size: inherit;
  width: 2.6em;
  height: 1.6em;
  max-width: calc(0.8em + 4vw);
  text-indent: -9999px; }
  @media (max-device-width: 768px) {
    trix-toolbar .trix-button--icon {
      height: 2em;
      max-width: calc(0.8em + 3.5vw); } }
  trix-toolbar .trix-button--icon::before {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.6;
    content: "";
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }
    @media (max-device-width: 768px) {
      trix-toolbar .trix-button--icon::before {
        right: 6%;
        left: 6%; } }
  trix-toolbar .trix-button--icon.trix-active::before {
    opacity: 1; }
  trix-toolbar .trix-button--icon:disabled::before {
    opacity: 0.125; }

trix-toolbar .trix-button--icon-attach::before {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M16.5%206v11.5c0%202.21-1.79%204-4%204s-4-1.79-4-4V5a2.5%202.5%200%200%201%205%200v10.5a1%201%200%201%201-2%200V6H10v9.5a2.5%202.5%200%200%200%205%200V5c0-2.21-1.79-4-4-4S7%202.79%207%205v12.5a5.5%205.5%200%200%200%2011%200V6h-1.5z%22%2F%3E%3C%2Fsvg%3E");
  top: 8%;
  bottom: 4%; }

trix-toolbar .trix-button--icon-bold::before {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M15.6%2011.79c.965-.675%201.65-1.765%201.65-2.79%200-2.255-1.745-4-4-4H7v14h7.04c2.095%200%203.71-1.7%203.71-3.79%200-1.52-.865-2.815-2.15-3.42zM10%207.5h3c.83%200%201.5.67%201.5%201.5s-.67%201.5-1.5%201.5h-3v-3zm3.5%209H10v-3h3.5c.83%200%201.5.67%201.5%201.5s-.67%201.5-1.5%201.5z%22%2F%3E%3C%2Fsvg%3E"); }

trix-toolbar .trix-button--icon-italic::before {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M10%205v3h2.215l-3.43%208H6v3h8v-3h-2.215l3.43-8H18V5h-8z%22%2F%3E%3C%2Fsvg%3E"); }

trix-toolbar .trix-button--icon-link::before {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M9.88%2013.697a4.294%204.294%200%200%201%200-6.066l3.374-3.375A4.261%204.261%200%200%201%2016.287%203a4.26%204.26%200%200%201%203.033%201.256%204.294%204.294%200%200%201%200%206.066l-1.962%201.715a.914.914%200%201%201-1.293-1.293l1.963-1.715c.96-.96.96-2.52%200-3.48a2.445%202.445%200%200%200-1.74-.72c-.658%200-1.276.255-1.741.72l-3.375%203.375c-.96.96-.96%202.52%200%203.48a.914.914%200%201%201-1.293%201.293Z%22%2F%3E%3Cpath%20d%3D%22M4.254%2019.458a4.294%204.294%200%200%201%200-6.065L6.18%2011.49a.914.914%200%201%201%201.292%201.293l-1.924%201.903a2.463%202.463%200%200%200%201.74%204.2c.658%200%201.275-.256%201.74-.72l3.376-3.375c.96-.96.96-2.52%200-3.48a.914.914%200%201%201%201.292-1.293%204.294%204.294%200%200%201%200%206.065l-3.375%203.375a4.261%204.261%200%200%201-3.033%201.256%204.261%204.261%200%200%201-3.033-1.256Z%22%2F%3E%3C%2Fsvg%3E"); }

trix-toolbar .trix-button--icon-strike::before {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12.728%2014c.122.057.216.104.282.141.262.147.451.293.57.436a.77.77%200%200%201%20.177.503c0%20.307-.147.558-.441.752-.293.195-.755.293-1.385.293-.731%200-1.533-.104-2.407-.312A13.521%2013.521%200%200%201%207%2014.946v3.373c.766.325%201.504.554%202.213.685s1.586.196%202.632.196c1.253%200%202.345-.187%203.276-.56.931-.374%201.644-.898%202.138-1.572S18%2015.623%2018%2014.754c0-.264-.021-.515-.062-.754h-5.21zM7.23%2010a4.635%204.635%200%200%201-.123-1.1c0-1.29.527-2.296%201.58-3.017C9.739%205.16%2011.186%204.8%2013.026%204.8c1.626%200%203.284.335%204.974%201.006l-1.3%202.922c-1.468-.6-2.735-.9-3.803-.9-.551%200-.952.086-1.203.258-.25.173-.376.387-.376.642%200%20.275.16.52.478.738.179.122.53.3%201.052.534H7.23zM3%2013h18v-2H3v2z%22%2F%3E%3C%2Fsvg%3E"); }

trix-toolbar .trix-button--icon-quote::before {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M6%2017h3l2-4V7H5v6h3zm8%200h3l2-4V7h-6v6h3z%22%2F%3E%3C%2Fsvg%3E"); }

trix-toolbar .trix-button--icon-heading-1::before {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M11.516%209v3h-3v6.984h-3V12h-3V9h9ZM8.484%203.984h13.031v3h-5.016v12h-3v-12H8.483v-3h.001Z%22%2F%3E%3C%2Fsvg%3E"); }

trix-toolbar .trix-button--icon-code::before {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M18.17%2012%2015%2015.17l1.415%201.415L21%2012l-4.585-4.585L15%208.83%2018.17%2012zM5.83%2012%209%208.83%207.585%207.415%203%2012l4.585%204.585L9%2015.17%205.83%2012z%22%2F%3E%3C%2Fsvg%3E"); }

trix-toolbar .trix-button--icon-bullet-list::before {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M4%204c-1.107%200-2%20.893-2%202s.893%202%202%202%202-.893%202-2-.893-2-2-2zm0%206c-1.107%200-2%20.893-2%202s.893%202%202%202%202-.893%202-2-.893-2-2-2zm0%206c-1.107%200-2%20.893-2%202s.893%202%202%202%202-.893%202-2-.893-2-2-2zm4%203h14v-2H8v2zm0-6h14v-2H8v2zm0-8v2h14V5H8z%22%2F%3E%3C%2Fsvg%3E"); }

trix-toolbar .trix-button--icon-number-list::before {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M2%2017h2v.5H3v1h1v.5H2v1h3v-4H2v1zm1-9h1V4H2v1h1v3zm-1%203h1.8L2%2013.1v.9h3v-1H3.2L5%2010.9V10H2v1zm5-6v2h14V5H7zm0%2014h14v-2H7v2zm0-6h14v-2H7v2z%22%2F%3E%3C%2Fsvg%3E"); }

trix-toolbar .trix-button--icon-undo::before {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12.5%208c-2.645%200-5.055.985-6.9%202.6L2%207v9h9l-3.615-3.615C8.77%2011.22%2010.545%2010.5%2012.5%2010.5a7.994%207.994%200%200%201%207.595%205.5l2.365-.78C21.085%2011.03%2017.15%208%2012.5%208z%22%2F%3E%3C%2Fsvg%3E"); }

trix-toolbar .trix-button--icon-redo::before {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M18.395%2010.6A10.416%2010.416%200%200%200%2011.5%208c-4.65%200-8.585%203.03-9.96%207.22l2.365.78a7.994%207.994%200%200%201%207.595-5.5c1.955%200%203.73.72%205.115%201.885L13%2016h9V7l-3.605%203.6z%22%2F%3E%3C%2Fsvg%3E"); }

trix-toolbar .trix-button--icon-decrease-nesting-level::before {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M3%2019h19v-2H3v2Zm7-6h12v-2H10v2Zm-8.293-.257%202.829%202.828%201.414-1.414-2.122-2.121L5.95%209.914%204.536%208.5%201%2012.036l.707.707ZM3%205v2h19V5H3Z%22%2F%3E%3C%2Fsvg%3E"); }

trix-toolbar .trix-button--icon-increase-nesting-level::before {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M3%2019h19v-2H3v2Zm7-6h12v-2H10v2Zm-6.879-.964L1%2014.156l1.414%201.415%203.536-3.535-.707-.708L2.414%208.5%201%209.914l2.121%202.122ZM3%205v2h19V5H3Z%22%2F%3E%3C%2Fsvg%3E"); }

trix-toolbar .trix-dialogs {
  position: relative; }

trix-toolbar .trix-dialog {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-size: 0.75em;
  padding: 15px 10px;
  background: #fff;
  box-shadow: 0 0.3em 1em #ccc;
  border-top: 2px solid #888;
  border-radius: 5px;
  z-index: 5; }

trix-toolbar .trix-input--dialog {
  font-size: inherit;
  font-weight: normal;
  padding: 0.5em 0.8em;
  margin: 0 10px 0 0;
  border-radius: 3px;
  border: 1px solid #bbb;
  background-color: #fff;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none; }
  trix-toolbar .trix-input--dialog.validate:invalid {
    box-shadow: #F00 0px 0px 1.5px 1px; }

trix-toolbar .trix-button--dialog {
  font-size: inherit;
  padding: 0.5em;
  border-bottom: none; }

trix-toolbar .trix-dialog--link {
  max-width: 600px; }

trix-toolbar .trix-dialog__link-fields {
  display: flex;
  align-items: baseline; }
  trix-toolbar .trix-dialog__link-fields .trix-input {
    flex: 1; }
  trix-toolbar .trix-dialog__link-fields .trix-button-group {
    flex: 0 0 content;
    margin: 0; }

trix-editor [data-trix-mutable]:not(.attachment__caption-editor) {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

trix-editor [data-trix-mutable]::-moz-selection,
trix-editor [data-trix-cursor-target]::-moz-selection, trix-editor [data-trix-mutable] ::-moz-selection {
  background: none; }

trix-editor [data-trix-mutable]::selection,
trix-editor [data-trix-cursor-target]::selection, trix-editor [data-trix-mutable] ::selection {
  background: none; }

trix-editor .attachment__caption-editor:focus[data-trix-mutable]::-moz-selection {
  background: highlight; }

trix-editor .attachment__caption-editor:focus[data-trix-mutable]::selection {
  background: highlight; }

trix-editor [data-trix-mutable].attachment.attachment--file {
  box-shadow: 0 0 0 2px highlight;
  border-color: transparent; }

trix-editor [data-trix-mutable].attachment img {
  box-shadow: 0 0 0 2px highlight; }

trix-editor .attachment {
  position: relative; }
  trix-editor .attachment:hover {
    cursor: default; }

trix-editor .attachment--preview .attachment__caption:hover {
  cursor: text; }

trix-editor .attachment__progress {
  position: absolute;
  z-index: 1;
  height: 20px;
  top: calc(50% - 10px);
  left: 5%;
  width: 90%;
  opacity: 0.9;
  transition: opacity 200ms ease-in; }
  trix-editor .attachment__progress[value="100"] {
    opacity: 0; }

trix-editor .attachment__caption-editor {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  color: inherit;
  text-align: center;
  vertical-align: top;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none; }

trix-editor .attachment__toolbar {
  position: absolute;
  z-index: 1;
  top: -0.9em;
  left: 0;
  width: 100%;
  text-align: center; }

trix-editor .trix-button-group {
  display: inline-flex; }

trix-editor .trix-button {
  position: relative;
  float: left;
  color: #666;
  white-space: nowrap;
  font-size: 80%;
  padding: 0 0.8em;
  margin: 0;
  outline: none;
  border: none;
  border-radius: 0;
  background: transparent; }
  trix-editor .trix-button:not(:first-child) {
    border-left: 1px solid #ccc; }
  trix-editor .trix-button.trix-active {
    background: #cbeefa; }
  trix-editor .trix-button:not(:disabled) {
    cursor: pointer; }

trix-editor .trix-button--remove {
  text-indent: -9999px;
  display: inline-block;
  padding: 0;
  outline: none;
  width: 1.8em;
  height: 1.8em;
  line-height: 1.8em;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid highlight;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25); }
  trix-editor .trix-button--remove::before {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.7;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg%20height%3D%2224%22%20width%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M19%206.41%2017.59%205%2012%2010.59%206.41%205%205%206.41%2010.59%2012%205%2017.59%206.41%2019%2012%2013.41%2017.59%2019%2019%2017.59%2013.41%2012z%22%2F%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3C%2Fsvg%3E");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 90%; }
  trix-editor .trix-button--remove:hover {
    border-color: #333; }
    trix-editor .trix-button--remove:hover::before {
      opacity: 1; }

trix-editor .attachment__metadata-container {
  position: relative; }

trix-editor .attachment__metadata {
  position: absolute;
  left: 50%;
  top: 2em;
  transform: translate(-50%, 0);
  max-width: 90%;
  padding: 0.1em 0.6em;
  font-size: 0.8em;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px; }
  trix-editor .attachment__metadata .attachment__name {
    display: inline-block;
    max-width: 100%;
    vertical-align: bottom;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  trix-editor .attachment__metadata .attachment__size {
    margin-left: 0.2em;
    white-space: nowrap; }

.trix-content {
  line-height: 1.5; }
  .trix-content * {
    box-sizing: border-box;
    margin: 0;
    padding: 0; }
  .trix-content h1 {
    font-size: 1.2em;
    line-height: 1.2; }
  .trix-content blockquote {
    border: 0 solid #ccc;
    border-left-width: 0.3em;
    margin-left: 0.3em;
    padding-left: 0.6em; }
  .trix-content [dir=rtl] blockquote,
  .trix-content blockquote[dir=rtl] {
    border-width: 0;
    border-right-width: 0.3em;
    margin-right: 0.3em;
    padding-right: 0.6em; }
  .trix-content li {
    margin-left: 1em; }
  .trix-content [dir=rtl] li {
    margin-right: 1em; }
  .trix-content pre {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    font-family: monospace;
    font-size: 0.9em;
    padding: 0.5em;
    white-space: pre;
    background-color: #eee;
    overflow-x: auto; }
  .trix-content img {
    max-width: 100%;
    height: auto; }
  .trix-content .attachment {
    display: inline-block;
    position: relative;
    max-width: 100%; }
    .trix-content .attachment a {
      color: inherit;
      text-decoration: none; }
      .trix-content .attachment a:hover, .trix-content .attachment a:visited:hover {
        color: inherit; }
  .trix-content .attachment__caption {
    text-align: center; }
    .trix-content .attachment__caption .attachment__name + .attachment__size::before {
      content: ' \2022 '; }
  .trix-content .attachment--preview {
    width: 100%;
    text-align: center; }
    .trix-content .attachment--preview .attachment__caption {
      color: #666;
      font-size: 0.9em;
      line-height: 1.2; }
  .trix-content .attachment--file {
    color: #333;
    line-height: 1;
    margin: 0 2px 2px 2px;
    padding: 0.4em 1em;
    border: 1px solid #bbb;
    border-radius: 5px; }
  .trix-content .attachment-gallery {
    display: flex;
    flex-wrap: wrap;
    position: relative; }
    .trix-content .attachment-gallery .attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%; }
    .trix-content .attachment-gallery.attachment-gallery--2 .attachment, .trix-content .attachment-gallery.attachment-gallery--4 .attachment {
      flex-basis: 50%;
      max-width: 50%; }
